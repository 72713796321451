import React from "react"
import styled from "@emotion/styled"

const Piece = props => {
  return (
    <PieceContainer onClick={props.onClick} selected={props.selected}>
      <SubtitleContainer>
        <DishName>{props.dishName}</DishName>
        <Price>{props.price}</Price>
      </SubtitleContainer>
      <Text>{props.text}</Text>
    </PieceContainer>
  )
}

const PieceContainer = styled.div`
  margin-bottom: 20px;
  cursor: pointer;
  color: ${props => (props.selected ? "red" : "")};
  margin-left: ${props => (props.selected ? "10px" : "0")};
  transition: all 0.5s;
`

const SubtitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const DishName = styled.p`
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
`

const Price = styled.p`
  font-weight: 600;
`

const Text = styled.p`
  max-width: 300px;
  font-size: 13px;
  opacity: 0.5;
`

export default Piece
