import React, { useState, Fragment } from "react"
import Piece from "../components/Piece"
import mochi from "../images/mochi.jpg"
import glaces from "../images/glaces.jpg"
import jap from "../images/DessertJap.png"

import {
  CategoryContainer,
  HalfTextContainer,
  CategoryName,
  ImageContainer,
  Jap,
} from "./shared/carte"

const Desserts = () => {
  const [selectedImage, setSelectedImage] = useState(mochi)

  const handlePictureChange = image => {
    setSelectedImage(image)
  }
  return (
    <Fragment>
      <CategoryContainer>
        <ImageContainer>
          <img className="bg-image" src={selectedImage} alt="dessert"></img>
          <Jap>
            <img rel="preload" src={jap} alt="dessert"></img>
          </Jap>
        </ImageContainer>
        <HalfTextContainer className="starters">
          <CategoryName>Desserts</CategoryName>
          <Piece
            name="mochi"
            onClick={handlePictureChange.bind(this, mochi)}
            dishName={"MOCHI MOCHI"}
            price={"6,00 €"}
            text={
              "Deux mochi mochi - Vanille, thé vert, fruit de la passion, cerise"
            }
            selected={selectedImage.includes("mochi")}
          />
          <Piece
            name="glaces"
            onClick={handlePictureChange.bind(this, glaces)}
            dishName={"GLACES"}
            price={"5,00 €"}
            text={
              "Deux boules de glace artisanale - Sésame noir, thé vert, gingembre, framboise au yuzu"
            }
            selected={selectedImage.includes("glaces")}
          />
        </HalfTextContainer>
      </CategoryContainer>
    </Fragment>
  )
}

export default Desserts
