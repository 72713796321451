import styled from "@emotion/styled"

export const CategoryContainer = styled.div`
  height: 100vh;
  display: flex;

  @media screen and (max-width: 640px) {
    height: auto;
    flex-wrap: wrap;
  }

  .arrowLink {
    z-index: 30;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 40px;
    text-decoration: none;
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
  }
`

export const ImageContainer = styled.div`
  width: 40%;
  overflow: hidden;
  position: relative;

  @media screen and (max-width: 640px) {
    height: 50vh;
    width: 100%;
  }

  .bg-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`
export const HalfTextContainer = styled.div`
  width: 60%;
  padding: 20px 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (max-width: 640px) {
    width: 100%;
    padding-bottom: 30%;
  }
`

export const CategoryName = styled.p`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
  text-align: center;
  text-transform: uppercase;
`

export const Jap = styled.div`
  position: absolute;
  left: 50%;
  bottom: 8%;
  transform: translateX(-50%);
  height: 70px;
  max-width: 70%;

  img {
    object-fit: contain;
    height: 100%;
    width: 100%;
  }
`
