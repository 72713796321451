import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Desserts from "../components/Desserts"

const IndexPage = () => (
  <Layout headerColor={"black"}>
    <SEO
      title="Les desserts du restaurant hara kiri ramen"
      description="Découvrez les differents desserts proposés par le restaurant hara kiri"
    />
    <Desserts />
  </Layout>
)

export default IndexPage
